import React, { useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { ReactComponentDefinition, useActions } from "@anvil-works/anvil-react";
import { inDesigner, useDesignerApi, useInlineEditRef } from "@anvil-works/anvil-react/designer";

export * from "./spacing";
export * from "./visible";
export * from "./variant";
export * from "./input";
export * from "./styles";
export * from "./icon";
export * from "./sizing";
export * from "./appearance";
export * from "./interaction";
export * from "./flex";

export type PropDef = NonNullable<ReactComponentDefinition["properties"]>[number];

export function useInlineEditLabel(label: string) {
    const { inDesigner } = useDesignerApi();
    const ref = useInlineEditRef("label");
    if (!inDesigner) return label;
    return <span ref={ref}>{label}</span>;
}

export function useSetDesignNameProp(prop: string, value: any, getPropValue?: (name: string) => any) {
    const { designName, inDesigner } = useDesignerApi();
    const { setProperty } = useActions();
    const doneRef = useRef(false);

    useEffect(() => {
        if (doneRef.current) return;
        if (!inDesigner) return;
        if (designName == null) return;
        if (value != null) return;
        try {
            let value: any = designName;
            if (getPropValue) {
                value = getPropValue(designName);
            }
            setProperty(prop, value);
            doneRef.current = true;
        } catch {}
    }, [designName]);
}

const camelToSnakeRegex = /([A-Z])/g;
const snakeToCamelRegex = /(_\w)/g;

export function toSnake(str: string): string {
    return str.replace(camelToSnakeRegex, (letter) => `_${letter.toLowerCase()}`);
}

export function toCamel(str: string): string {
    return str.replace(snakeToCamelRegex, (matches) => matches[1].toUpperCase());
}

export function snakeCasePropDescriptors(props: ReactComponentDefinition["properties"]) {
    const snakeToCamelMap = Object.fromEntries(props?.map(({ name }) => [toSnake(name), name]) ?? []);
    const snakeProperties: ReactComponentDefinition["properties"] =
        props?.map((prop) => ({
            ...prop,
            name: toSnake(prop.name),
        })) ?? [];
    const propsWithDefaultNoneOption =
        props?.filter((prop) => prop.type === "enum" && prop.includeNoneOption).map(({ name }) => name) ?? [];

    const propsWithNumber = props?.filter((prop) => prop.type === "number").map(({ name }) => name) ?? [];
    const convertNullToUndefined = [...propsWithDefaultNoneOption, ...propsWithNumber];
    const convertEmptyToUndefined =
        props?.filter((prop) => prop.type === "string" || prop.type === "color").map(({ name }) => name) ?? [];

    const camelCaseProps = (properties: Record<string, any>) => {
        const camelProps: Record<string, any> = {};
        for (const p in properties) {
            camelProps[snakeToCamelMap[p] ?? p] = properties[p];
        }
        for (const nullEnumProp of convertNullToUndefined) {
            camelProps[nullEnumProp] ??= undefined;
        }
        for (const emptyProp of convertEmptyToUndefined) {
            const val = camelProps[emptyProp];
            if (val === null || val === "") {
                camelProps[emptyProp] = undefined;
            }
        }
        return camelProps;
    };
    const snakeCaseProps = (properties: Record<string, any>) => {
        const snakeProps: Record<string, any> = {};
        for (const p in properties) {
            snakeProps[toSnake(p)] = properties[p];
        }
        return snakeProps;
    };

    return { snakeProperties, camelCaseProps, snakeCaseProps };
}

export function usePlaceholder(label: React.ReactNode, { defaultValue }: { defaultValue?: string } = {}) {
    const [inlineEditing, setInlineEditing] = useState(false);
    const onStart = () => {
        flushSync(() => {
            setInlineEditing(true);
        });
    };
    const onEnd = () => {
        setInlineEditing(false);
    };
    const designerApi = useDesignerApi();

    let placeholder;
    if (inDesigner && !label && !inlineEditing) {
        placeholder = (
            <span style={{ fontStyle: "italic", opacity: 0.7, pointerEvents: "none" }}>
                {defaultValue || designerApi.designName}
            </span>
        );
    }
    return [placeholder, { onStart, onEnd }, inlineEditing] as const;
}

export function omit<T extends { name: string }>(props: T[], names: string[]) {
    return props.filter(({ name }) => !names.includes(name));
}

export function pick<T extends PropDef>(props: T[], pred: string[] | ((name: string) => boolean)) {
    if (typeof pred === "function") {
        return props.filter(({ name }) => pred(name));
    }
    return props.filter(({ name }) => pred.includes(name)) as T[];
}

export function override(props: PropDef[], overrides: Record<string, Partial<PropDef>>) {
    const rv = [...props];
    for (const [propName, propDef] of Object.entries(overrides)) {
        const index = rv.findIndex(({ name }) => name === propName);
        if (index < 0) continue;
        rv[index] = { ...rv[index], ...propDef } as PropDef;
    }
    return rv;
}
